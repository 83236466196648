import { useRouter } from 'next/router'

import logger from '@knauf-group/ct-shared-nextjs/logger'
import { useFlag, useVariant } from '@unleash/nextjs'

/**
 * In Unleash
 * variant name: locales
 * payload: 
 * {
     "isActive": false,
     "locales": ["nl-be", "fr-be"]
   }
 */
type VariantPayload = {
  isActive: boolean
  locales: string[]
}

// with this hook we validate if the filter chips toggle is activated per environment and
// also if the locale variant (string of locales) includes current locale from useRouter
// NOTE: make sure in unleash console the varint has a json payload of type VariantPayload
const useFlagWithPerLocaleVariant = (name: string) => {
  const { locale } = useRouter()
  const flag = useFlag(name)
  const flagVariants = useVariant(name)

  // is flag is false, we return false
  if (!flag) return flag

  // if we don't have any variants configured we return the flag value, which is true
  if (!flagVariants.enabled) return flag

  // we parce the payload that should be of VariantPayload type
  try {
    const payload: VariantPayload = JSON.parse(flagVariants.payload?.value ?? '')
    if (!payload.isActive) return flag

    const flagLocales = payload.locales.map((loc) => loc.trim().toLowerCase())
    return flagLocales.includes(locale.toLowerCase()) ?? false
  } catch (error: any) {
    logger.error(error?.message)
    return false
  }
}

export const useUnleash = () => {
  const isEnabledTypeFilters = useFlagWithPerLocaleVariant('pcat-type-filters')

  return {
    isEnabledTypeFilters,
  }
}
